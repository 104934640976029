export class SignalSource<T extends Record<string, unknown[]>> {
  readonly #listeners: {
    event: keyof T
    listener: (...args: T[keyof T]) => void
  }[] = []

  emit (event: keyof T, ...args: T[typeof event]) {
    for (const { event: _event, listener } of this.#listeners) {
      if (event === _event) {
        listener(...args)
      }
    }
  }

  subscribe (event: keyof T, listener: (...args: T[typeof event]) => void) {
    this.#listeners.push({ event, listener })
  }

  unsubscribe (event: keyof T, listener: (...args: T[typeof event]) => void) {
    const listenerIndex = this.#listeners.findIndex(({ event: _event, listener: _listener }) => event === _event && listener === _listener)
    if (listenerIndex !== -1) {
      this.#listeners.splice(listenerIndex, 1)
    }
  }
}