// JsFromRoutes CacheKey b34c32af99bc8ad7cea328077b3d211e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  create: definePathHelper('post', '/template_packs'),
  list: definePathHelper('get', '/template_packs'),
  destroy: definePathHelper('delete', '/template_packs'),
  update: definePathHelper('patch', '/template_packs/:id'),
  conflicts: definePathHelper('get', '/template_packs/:id/conflicts'),
  revert: definePathHelper('post', '/template_packs/:id/revert'),
  unblacklistUuid: definePathHelper('post', '/template_packs/:id/unblacklist_uuid'),
  templatePackQuestionnaireVersions: definePathHelper('get', '/template_packs/:id/template_pack_questionnaire_versions'),
  attributesByTemplates: definePathHelper('get', '/template_packs/attributes_by_templates'),
  importFolder: definePathHelper('get', '/template_packs/import_folder'),
}
