// JsFromRoutes CacheKey 1704d45a77ba6524aec5293ea13f3c8e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  twoFactorQr: definePathHelper('get', '/users/two_factor_qr'),
  edit: definePathHelper('get', '/users/edit'),
  update: definePathHelper('patch', '/users/:id'),
}
