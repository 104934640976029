// JsFromRoutes CacheKey 49231d5c476dc2e982ff881259e8d2cf
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  assign: definePathHelper('post', '/workflows/approval_requests/:id/assign'),
  unassign: definePathHelper(
    'post',
    '/workflows/approval_requests/:id/unassign'
  ),
  create: definePathHelper('post', '/workflows/approval_requests'),
  update: definePathHelper('patch', '/workflows/approval_requests/:id')
}
