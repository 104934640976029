// JsFromRoutes CacheKey c18908dd64d1bd66e42a526bb548a187
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  hydrate: definePathHelper('get', '/forms/hydrate'),
  list: definePathHelper('get', '/forms'),
  create: definePathHelper('post', '/forms'),
  get: definePathHelper('get', '/forms/:id'),
  update: definePathHelper('patch', '/forms/:id'),
  destroy: definePathHelper('delete', '/forms/:id')
}
