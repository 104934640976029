// JsFromRoutes CacheKey 760abaf8bdbba6b17b7bfe26de177dfa
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  new: definePathHelper('get', '/users/unlock/new'),
  get: definePathHelper('get', '/users/unlock'),
  create: definePathHelper('post', '/users/unlock'),
}
