// JsFromRoutes CacheKey d842015029d4569a84b2002973e8339c
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  retry: definePathHelper('post', '/integrations/synchronizations/:id/retry'),
  query: definePathHelper('post', '/integrations/synchronizations/query'),
  manage: definePathHelper(
    'get',
    '/integrations/synchronizations/manage/:scope/:id'
  ),
  get: definePathHelper(
    'get',
    '/integrations/synchronizations/:integration/:scope/:id'
  ),
  list: definePathHelper('get', '/integrations/synchronizations'),
  create: definePathHelper('post', '/integrations/synchronizations'),
  destroy: definePathHelper('delete', '/integrations/synchronizations/:id')
}
