// JsFromRoutes CacheKey ffae1d9058a190dca527702f93e11965
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  chat: definePathHelper('post', '/ai/chat'),
  action: definePathHelper('get', '/ai/action'),
  clear: definePathHelper('post', '/ai/clear'),
  reviewXlsx: definePathHelper('post', '/ai/review_xlsx'),
  docxToEntries: definePathHelper('post', '/ai/docx_to_entries'),
  buildOperation: definePathHelper('post', '/ai/build_operation'),
  generateQuestions: definePathHelper('post', '/ai/generate_questions'),
  amendText: definePathHelper('post', '/ai/amend_text'),
  conditionBuilder: definePathHelper('post', '/ai/condition_builder'),
  conditionBuilderRevise: definePathHelper('post', '/ai/condition_builder_revise'),
  reviewDealDatasheet: definePathHelper('post', '/ai/review_deal_datasheet'),
}
