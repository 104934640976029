// JsFromRoutes CacheKey 31a4f54dbaac16198aa5fb0689c86a96
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  confirmDeletion: definePathHelper('post', '/template_packs/clips/confirm_deletion'),
  archive: definePathHelper('post', '/template_packs/clips/archive'),
  restore: definePathHelper('post', '/template_packs/clips/restore'),
  duplicate: definePathHelper('post', '/template_packs/clips/duplicate'),
  list: definePathHelper('get', '/template_packs/clips'),
  get: definePathHelper('get', '/template_packs/clips/:id'),
}
