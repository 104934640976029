// JsFromRoutes CacheKey d2855ce6fa59548a5da0c52be82ca03d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  create: definePathHelper(
    'post',
    '/template_versions/:template_version_id/smart_automation'
  )
}
