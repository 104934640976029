// JsFromRoutes CacheKey 4f70431656d0d4cec84d68c84bc171ad
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  export: definePathHelper('get', '/docu_sign/batches/:id/export'),
  modal: definePathHelper('get', '/docu_sign/batches/:id/modal'),
  public: definePathHelper('get', '/docu_sign/batches/:id/public'),
  publish: definePathHelper('post', '/docu_sign/batches/:id/publish'),
  unpublish: definePathHelper('delete', '/docu_sign/batches/:id/unpublish'),
  refresh: definePathHelper('post', '/docu_sign/batches/:id/refresh'),
  fields: definePathHelper('patch', '/docu_sign/batches/:id/fields'),
  list: definePathHelper('get', '/docu_sign/batches'),
  create: definePathHelper('post', '/docu_sign/batches'),
  get: definePathHelper('get', '/docu_sign/batches/:id'),
  update: definePathHelper('patch', '/docu_sign/batches/:id'),
  destroy: definePathHelper('delete', '/docu_sign/batches/:id')
}
