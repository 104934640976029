// JsFromRoutes CacheKey beaeabd3501b25b24eb4902ae3e7e770
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  body: definePathHelper(
    'get',
    '(/:admin)/custom_clauses/:custom_clause_id/variants/:id/body'
  )
}
