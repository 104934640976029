// JsFromRoutes CacheKey fb2bb5d331432be0bfec5a3716c5e75b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  xlsx: definePathHelper('post', '/deals/:deal_id/import/xlsx'),
  templateForOrganisation: definePathHelper('post', '/deals/:deal_id/import/template_for_organisation'),
  template: definePathHelper('post', '/deals/:deal_id/import/template'),
  templates: definePathHelper('post', '/deals/:deal_id/import/templates'),
  data: definePathHelper('post', '/deals/:deal_id/import/data'),
  templatesForImport: definePathHelper('get', '/deals/:deal_id/import/templates_for_import'),
  dataForImport: definePathHelper('get', '/deals/:deal_id/import/data_for_import'),
}
