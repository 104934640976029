// JsFromRoutes CacheKey acae9c3c2f4323339355c5fb9b3ae29a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  action: definePathHelper('post', '/deals/:deal_id/execution/action'),
  retry: definePathHelper('post', '/deals/:deal_id/execution/retry'),
  cancel: definePathHelper('post', '/deals/:deal_id/execution/cancel'),
  get: definePathHelper('get', '/deals/:deal_id/execution'),
  update: definePathHelper('patch', '/deals/:deal_id/execution'),
}
