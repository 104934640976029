// JsFromRoutes CacheKey 846ea65da780a181d32ca54a870e54a4
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/groups'),
  create: definePathHelper('post', '/groups'),
  edit: definePathHelper('get', '/groups/edit'),
  update: definePathHelper('patch', '/groups'),
  get: definePathHelper('get', '/groups/:id'),
}
