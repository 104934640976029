// JsFromRoutes CacheKey 30b15826b5ce7fc371907bf2e61f9e33
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  revisions: definePathHelper('post', '/deals/:deal_id/preview/revisions'),
  document: definePathHelper('post', '/deals/:deal_id/preview/document'),
  exportEnvelopes: definePathHelper('post', '/deals/:deal_id/preview/export_envelopes'),
  exportDocuments: definePathHelper('post', '/deals/:deal_id/preview/export_documents'),
  get: definePathHelper('get', '/deals/:deal_id/preview'),
}
