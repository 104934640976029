// JsFromRoutes CacheKey c4a99b0e6c2496fe77efd96045fb712e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  new: definePathHelper('get', '/users/password/new'),
  edit: definePathHelper('get', '/users/password/edit'),
  update: definePathHelper('patch', '/users/password'),
  create: definePathHelper('post', '/users/password'),
}
