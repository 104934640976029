// JsFromRoutes CacheKey 66a23efe1384f17826a758cd946d3bbf
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  create: definePathHelper('post', '/profiles'),
  edit: definePathHelper('get', '/profiles/:id/edit'),
  get: definePathHelper('get', '/profiles/:id'),
  update: definePathHelper('patch', '/profiles/:id'),
}
