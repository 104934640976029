// JsFromRoutes CacheKey 15f07064bbee3fbdae30c3a694a590b5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  new: definePathHelper('get', '/users/confirmation/new'),
  get: definePathHelper('get', '/users/confirmation'),
  create: definePathHelper('post', '/users/confirmation'),
}
