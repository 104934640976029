// JsFromRoutes CacheKey caa6700fe05376084312b31799aadb02
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  new: definePathHelper('get', '/users/sign_in'),
  create: definePathHelper('post', '/users/sign_in'),
  destroy: definePathHelper('delete', '/users/sign_out'),
}
