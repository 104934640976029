// JsFromRoutes CacheKey 9d1f220a32d36046d96bf04f7a0f9238
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  cancel: definePathHelper('get', '/users/cancel'),
  new: definePathHelper('get', '/users/sign_up'),
  edit: definePathHelper('get', '/users/edit'),
  update: definePathHelper('patch', '/users'),
  destroy: definePathHelper('delete', '/users'),
  create: definePathHelper('post', '/users'),
}
