// JsFromRoutes CacheKey 44e6f26cc800e7fa8b759897c8995f38
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  finish: definePathHelper('post', '/documents/finish'),
  entries: definePathHelper('get', '/documents/:id/entries'),
  state: definePathHelper('get', '/documents/:id/state'),
  saveTitle: definePathHelper('post', '/documents/:id/save_title'),
  updateNotifications: definePathHelper('post', '/documents/:id/update_notifications'),
  updateSettings: definePathHelper('post', '/documents/:id/update_settings'),
}
