// JsFromRoutes CacheKey 2331eab175647ce1353f10df912f5885
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  finalizeQuestionnaire: definePathHelper(
    'post',
    '/clips/:id/finalize_questionnaire'
  ),
  sync: definePathHelper('post', '/clips/:id/sync')
}
