// JsFromRoutes CacheKey bb0d9c31b543d54f949255d3a952f216
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  create: definePathHelper('post', '/deals/:deal_id/templates'),
  update: definePathHelper('patch', '/deals/:deal_id/templates/:id'),
  destroy: definePathHelper('delete', '/deals/:deal_id/templates/:id'),
}
